import { DocumentSearch, SettingIcon } from '@components/icons';
import { useColumnSearch } from '@components/ui/table/tableHooks';
import { ActorAndInfo, StatusTag } from '@components';
import { dateToString } from '@shared/utils/Date';
import { Button, Space, Table } from '@ui';
import { ColumnType } from 'antd/es/table';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { TableColumnsType } from 'antd';
import { CodeLabFilePath } from './CodeLabFilePath';
import { ICodeLabJobListResponse, ICodeLabSourceListResponse } from '../CodeLabTypes';
import { codeLabActions } from '../duck/codeLabSlice';
import { CodeLabModalsType } from '../modals';

export const CodeLabJobList = ({
  jobList,
  sourceList,
  loading,
  mixClSchedulerDelete,
  mixClSchedulerInsert,
}: ICodeLabJobListProps) => {
  const { t } = useTranslation(['codeLab']);
  const { getColumnSearchProps, locale } = useColumnSearch<ICodeLabSourceListResponse[0]>();
  const dispatch = useDispatch();

  const displayActions = mixClSchedulerInsert || mixClSchedulerDelete;

  const tableData = useMemo(() => {
    return (sourceList || []).map((item) => {
      const jobData = (jobList || []).find((jobItem) => jobItem.notebook_id === item.note_id);
      return { ...item, jobData };
    }, {} as Array<ICodeLabSourceListResponse[0] & { jobData?: ICodeLabJobListResponse['items'][0] }>);
  }, [jobList, sourceList]);

  const { columns }: { columns: TableColumnsType<(typeof tableData)[0]> } = useMemo(() => {
    const tableColumns = [
      {
        title: t('rootTable.fileName'),
        dataIndex: 'file_path',
        ...getColumnSearchProps('file_path'),
        render: (_, record) => <CodeLabFilePath filePath={record.file_path} />,
      },
      {
        title: t('rootTable.schedulerUpdated'),
        dataIndex: 'schedulerUpdated',
        customKey: 'narrow',
        render: (_, record) =>
          record.jobData ? (
            record.jobData?.created_at ? (
              <ActorAndInfo
                info={dateToString(record.jobData?.updated_at || record.jobData?.created_at)}
                actor={record.jobData?.updated_by || record.jobData?.created_by}
              />
            ) : (
              t('na')
            )
          ) : (
            <></>
          ),
      },
      {
        title: t('rootTable.lastFinished'),
        dataIndex: 'lastFinished',
        render: (_, record) =>
          record.jobData ? (
            record.jobData?.finished_at ? (
              dateToString(record.jobData?.finished_at, true)
            ) : (
              <>&mdash;</>
            )
          ) : (
            <></>
          ),
      },
      {
        width: 150,
        title: t('rootTable.status'),
        dataIndex: 'lastFinished',
        render: (_, record) =>
          record.jobData ? (
            <StatusTag
              showSuccessTag={record.jobData?.status === 'Success'}
              showErrorTag={record.jobData?.status === 'Error'}
              errorMessage={record.jobData?.error}
            />
          ) : (
            <></>
          ),
      },
      {
        hidden: !displayActions,
        width: 100,
        title: t('rootTable.actions'),
        dataIndex: 'actionMenu',
        render: (_: any, record) => {
          return (
            <Space full>
              <Button
                title={t('rootTable.viewConfig')}
                icon={<SettingIcon color="darkGrey" />}
                loading={false}
                onClick={() =>
                  dispatch(
                    codeLabActions.pushModal({
                      type: CodeLabModalsType.scheduler,
                      data: { ...record.jobData, name: record.file_path, notebook_id: record.note_id },
                    }),
                  )
                }
              />
              {record.jobData?.id && (
                <Button
                  title={t('rootTable.showLogs')}
                  icon={<DocumentSearch />}
                  onClick={() =>
                    dispatch(
                      codeLabActions.pushModal({
                        type: CodeLabModalsType.logs,
                        data: { codeLabJobId: record.jobData?.notebook_id },
                      }),
                    )
                  }
                />
              )}
            </Space>
          );
        },
      },
    ] as ColumnType<(typeof tableData)[0]>[];

    return { columns: tableColumns };
  }, [dispatch, getColumnSearchProps, t, displayActions]);

  return (
    <Table
      locale={locale}
      columns={columns}
      dataSource={tableData}
      rowKey={(record) => record.note_id}
      loading={loading}
      tableLayout="fixed"
      scroll={{ x: 900 }}
      pagination={false}
    />
  );
};

interface ICodeLabJobListProps {
  jobList?: ICodeLabJobListResponse['items'];
  sourceList?: ICodeLabSourceListResponse;
  loading?: boolean;
  mixClSchedulerInsert?: boolean;
  mixClSchedulerDelete?: boolean;
}
