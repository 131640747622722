import { Button, Space, Tooltip } from '@ui';
import { InternalSider } from '@app/components';
import { ReactJSXElement } from '@emotion/react/types/jsx-namespace';
import { getFolderIconColor, getTableNameWithSchema } from '@shared/utils/Viewer';
import { ViewerGroupType, ViewerGroupTypeNames } from '@modules/viewer/ViewerTypes';
import { Refetch } from '@components/icons';
import { selectAppliedENVSwitch, selectGlobalStudy } from '@app/duck/appSelectors';
import { selectStudyActiveUserRole } from '@modules/study/duck/studySelectors';
import { FolderFilled, FolderOpenFilled } from '@components/icons';
import { HolderOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import React, { DragEvent, useEffect, useMemo, useRef } from 'react';
import { CSSObject, Theme } from '@emotion/react';
import Scrollbars from 'react-custom-scrollbars-2';
import TreeView, { flattenTree, INode, INodeRendererProps, ITreeViewProps, NodeId } from 'react-accessible-treeview';
import { TableListSiderToolbar } from './TableListSiderToolbar';
import { NodeData } from './tableListSiderHooks';

const getFolderIcon = (expanded: boolean, groupType: ViewerGroupType) => {
  const Icon = expanded ? FolderOpenFilled : FolderFilled;
  const color = getFolderIconColor(groupType);
  const title = ViewerGroupTypeNames[groupType];

  return (
    <Tooltip title={title} key={`${expanded}-${groupType}`}>
      <Icon css={cssFolderIcon(color)} />
    </Tooltip>
  );
};

export const TableListSiderTreeItem = ({
  node,
  draggable,
  selectedTable,
  onSelectTable,
  refetch,
  isExternalRoute,
}: ITableListSiderTreeItemProps) => {
  const selectedTableRef = useRef<HTMLDivElement>(null);
  const appliedENVSwitch = useSelector(selectAppliedENVSwitch) || '';

  useEffect(() => {
    if (selectedTableRef.current && isExternalRoute && selectedTable) {
      const tableName = getTableNameWithSchema(selectedTable).name;
      if (tableName && tableName === selectedTableRef.current.innerText) {
        const selectedTableRect = selectedTableRef.current.getBoundingClientRect();
        const windowHeight = window.innerHeight;

        if (selectedTableRect.top > windowHeight / 2) {
          selectedTableRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
      }
    }
  }, [selectedTable, isExternalRoute, appliedENVSwitch]);

  const isSelected = useMemo(
    () =>
      selectedTable
        ? // eslint-disable-next-line eqeqeq
          selectedTable.split(':').at(0) == node.element.id || node.element.id === selectedTable
        : false,
    [selectedTable, node.element.id],
  );

  const renderContent = useMemo(() => {
    const { name, id, parent, metadata = {} } = node.element;

    if (!node.isBranch) {
      return (
        <Space block full justify="space-between" css={isSelected ? cssTreeSelectedItem : undefined}>
          <div ref={selectedTableRef} css={cssTreeLabel} onClick={node.handleSelect}>
            {draggable ? <HolderOutlined css={cssDraggableIcon} /> : <React.Fragment />}
            <span css={cssTreeNodeName}>{name}</span>
          </div>
          {isSelected && (
            <Button
              css={cssTreeRefetchBtn}
              className="refetchBtn"
              icon={<Refetch css={cssRefetchIcon} color="darkGrey" />}
              size="small"
              onClick={() => {
                onSelectTable(id as string, name, parent);
                selectedTable && refetch();
              }}
            />
          )}
        </Space>
      );
    } else {
      return (
        <Space full justify="space-between" className={isSelected ? 'DataViewer-active-leaf' : ''}>
          <div css={cssTreeLabel}>
            {getFolderIcon(node.isExpanded, metadata?.groupType as ViewerGroupType)}
            <span css={cssFolderName}>{name}</span>
          </div>
        </Space>
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    node.isBranch,
    node.element.name,
    node.element.id,
    node.isExpanded,
    node.element.metadata?.groupType,
    isSelected,
    draggable,
    selectedTable,
  ]);

  return renderContent;
};

export const TableListSiderTemplate = (props: ITableListSiderTemplateProps) => {
  const appliedENVSwitch = useSelector(selectAppliedENVSwitch) || '';
  const globalStudy = useSelector(selectGlobalStudy);
  const studyActiveRole = useSelector(selectStudyActiveUserRole);

  const flattenedData = useMemo(() => {
    if (props?.treeData) {
      const originData: NodeData = {
        id: 'root',
        name: '',
        children: props?.treeData ?? [],
      };
      return flattenTree(originData);
    }
    return [];
  }, [props.treeData]);

  const nodeRender = (node: INodeRendererProps) => (
    <div
      key={node.element.id}
      {...node.getNodeProps({ onClick: node.handleExpand })}
      draggable={props.draggable}
      onDragStart={(event) => {
        props.onDragStart(event, node.element);
      }}
    >
      {props.titleRender(node, props.draggable)}
    </div>
  );

  return (
    <InternalSider>
      <TableListSiderToolbar
        reloadTables={props.reloadTables}
        isErrorLoadingTables={props.isErrorLoadingTables}
        isLoadingData={props.isLoadingData}
        setSearch={props.setSearch}
      />
      <Scrollbars
        autoHide={false}
        renderThumbHorizontal={(props) => <div css={cssScroll} {...props} />}
        renderThumbVertical={(props) => <div css={cssScroll} {...props} />}
      >
        {flattenedData.length > 0 && (
          <TreeView
            key={`${globalStudy?.id!}-${appliedENVSwitch}-${studyActiveRole?.role_id}`}
            css={cssTree}
            data={flattenedData}
            aria-label="data viewer tree"
            nodeRenderer={nodeRender}
            onExpand={props.onExpand}
            onNodeSelect={props.onSelect}
            selectedIds={props.selectedKeys}
            expandedIds={props.expandedKeys}
          />
        )}
      </Scrollbars>
    </InternalSider>
  );
};

interface ITableListSiderTreeItemProps {
  node: INodeRendererProps;
  onSelectTable: (key: string, title: string, folder: NodeId | null) => void;
  refetch: () => void;
  selectedTable: string | null | undefined;
  isExternalRoute?: boolean;
  draggable?: boolean;
}

export interface ITableListSiderTemplateProps {
  treeData?: NodeData[];
  titleRender: (props: INodeRendererProps, draggable?: boolean) => ReactJSXElement;
  selectedKeys: string[];
  expandedKeys: React.Key[];
  selectedTable?: string | null;
  defaultTableName?: string | null;
  defaultFolderName?: string | null;
  draggable?: boolean;
  refetch: () => void | null;
  isLoadingData: boolean;
  reloadTables: () => void;
  isErrorLoadingTables: boolean;
  setSearch: (searchText: string) => void;
  onSelect: ITreeViewProps['onNodeSelect'];
  onExpand: ITreeViewProps['onExpand'];
  onDragStart: (event: DragEvent, nodeProps: INode) => void;
}

const cssDraggableIcon = (): CSSObject => ({
  width: '24px',
  opacity: 0.2,
  transition: 'opacity 0.3s',
});

const cssTreeRefetchBtn = (theme: Theme): CSSObject => ({
  display: 'none',
  background: 'transparent',
  border: 'none',
  '&&&: hover': {
    background: theme['color-grey-300'],
  },
});

const cssTreeSelectedItem = (): CSSObject => ({
  '&:hover': {
    '.refetchBtn': {
      display: 'inline-block',
    },
  },
});

const cssTreeNodeName = (): CSSObject => ({
  fontSize: '14px',
  lineHeight: '21px',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  padding: '4px 0',
});

const cssFolderName = (): CSSObject => ({
  ...cssTreeNodeName(),
  paddingLeft: '10px',
});

const cssScroll = (theme: Theme): CSSObject => ({
  backgroundColor: theme.colorBorder,
  zIndex: 2,
  borderRadius: '1rem',
  opacity: 0.7,
});

const cssTree = (theme: Theme): CSSObject => ({
  '&&.tree': {
    listStyle: 'none',
    padding: '12px',
    margin: 0,
    background: 'transparent',
    borderRadius: 0,
    boxSizing: 'border-box',
    color: theme['color-grey-600'],
    fontSize: '14px',
    lineHeight: 1.5,
    transition: 'background-color 0.3s',

    '&& .ant-space-item:first-of-type': {
      overflow: 'hidden',
      width: '100%',
    },

    '&& .tree-branch-wrapper': {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
    },

    '&& .tree-node__branch': {
      marginBottom: '4px',
    },

    '&& .tree-node': {
      listStyle: 'none',
      lineHeight: '24px',
      display: 'flex',
      alignItems: 'flex-start',
      padding: 0,
      paddingLeft: '8px',
      outline: 'none',
      cursor: 'pointer',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      width: '100%',
      borderRadius: '4px',
    },

    '&& .tree-node--selected': {
      backgroundColor: theme['color-brand-blue-100'],
      color: theme['color-brand-blue-500'],
      padding: '0 4px',
    },

    '&&& .tree-node--selected:hover': {
      backgroundColor: theme['color-grey-200'],
    },

    '&& .tree-node:hover': {
      backgroundColor: theme['color-grey-100'],
    },

    '&& .tree-node-group': {
      listStyle: 'none',
      padding: 0,
      paddingLeft: '9px',
      marginLeft: '16px',
      borderLeft: `3px solid ${theme['color-grey-200']}`,
    },

    '&& .tree-leaf-list-item': {
      padding: '0 0 4px 0',
      color: 'inherit',
      lineHeight: '24px',
      background: 'transparent',
      cursor: 'pointer',
      transition: 'all 0.2s, border 0s, line-height 0s, box-shadow 0s',
    },

    '&& .tree-node__leaf': {
      padding: '0 12px',
      display: 'flex',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
    },
  },

  '&& .tree-node:has(.DataViewer-active-leaf):hover': {
    backgroundColor: theme['color-grey-100'],
  },
});

const cssTreeLabel = (): CSSObject => ({
  display: 'flex',
  alignItems: 'center',
  width: '100%',
});

const cssRefetchIcon = (): CSSObject => ({
  height: 16,
  width: 16,
});

const cssFolderIcon = (color: string) => (): CSSObject => ({
  width: '24px',
  height: '24px',
  flexShrink: 0,
  color,
});
